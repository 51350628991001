import './App.css';
import './signup.css';
import demoImg from './img/demo-sc.png';
import logoImg from './img/notifs-logo.png';

import { supabase } from './supabaseClient.js';

import Details from './Details.js';
import Form from './Form.js';
import BottomCard from './BottomCard.js';

const { data, error } = await supabase.auth.signInAnonymously();

function App() {
  
  if(data || error){}

  return (
    <div className="App">
      
  
        <header>
          <img className='logo' src={logoImg} alt="logo" />
          
        </header>
        <div className='lead-in'>
          <h1 className='slogan'>
           Work notifications in your browser.
          </h1>
          
          <p className='smaller-slogan'>
            Control your notifications,<br /> and focus on what matters most to you.
          </p>
          
          <div className='valid-popup'></div>
          
          <Form />

          <div className='demo-img'>
            <img src={demoImg} alt="demo" />
          </div>
        </div>
        
        <Details />
        
        <BottomCard />
        
      
    </div>
  );
}

export default App;
