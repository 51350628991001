import './App.css';
import logosGroupImg from './img/logo-grouping.png';
import extViewImg from './img/extension-view.png';
import selectionViewImg from './img/selection-view.png';

import balanceIcon from './icons/balance-icon.png';
import bellIcon from './icons/bell-icon.png';
import quietIcon from './icons/quiet-icon.png';
import timeIcon from './icons/time-icon.png';
import towerIcon from './icons/tower-icon.png';



export default function Details() {
  return (
    <div className='details-section'>
      <h1 className='section-slogan'>Take back your time</h1>

      <div className='details-flex-group'>
        <div className='detail-card'>
          <div className='detail-img-container'>
            <img src={logosGroupImg} alt="demo" className='detail-img' />
          </div>
          
          <p className='detail-text'>Centralize your work apps in one place.</p>
        </div>
        
        <div className='detail-card'>
          <div className='detail-img-container'>
            <img src={selectionViewImg} alt="demo" className='detail-img' />
          </div>
          
          <p className='detail-text'>Select switch apps easily.</p>
        </div>

        <div className='detail-card'>
          <div className='detail-img-container'>
            <img src={extViewImg} alt="demo" className='detail-img' />
          </div>
          
          <p className='detail-text'>Access your notifications where you work the most.</p>
        </div>
        
      </div>


      

      <div className='features-section'>
        <h1 className='section-slogan'>Benefits</h1>
        
        
        <div className='features'>
          <div className='feature-container'>
            <div className='feature-icon-container'>
              <img src={towerIcon} alt="demo-icon" className='feature-icon' />
            </div>
            <div className='feature-text-container'>
              <p className='feature-text'>Central view for what's happening. </p>
            </div>
          </div>

          <div className='feature-container'>
            <div className='feature-icon-container'>
              <img src={timeIcon} alt="demo-icon" className='feature-icon' />
            </div>
            <div className='feature-text-container'>
              <p className='feature-text'>Save time switching between apps.</p>
            </div>
          </div>
          
          <div className='feature-container'>
            <div className='feature-icon-container'>
              <img src={quietIcon} alt="demo-icon" className='feature-icon' />
            </div>
            <div className='feature-text-container'>
              <p className='feature-text'>Reduce distractions from looking at your phone.</p>
            </div>
          </div>

          <div className='feature-container'>
            <div className='feature-icon-container'>
              <img src={bellIcon} alt="demo-icon" className='feature-icon' />
            </div>
            <div className='feature-text-container'>
              <p className='feature-text'>Filter and see only important notifications. </p>
            </div>
          </div>

          <div className='feature-container'>
            <div className='feature-icon-container'>
              <img src={balanceIcon} alt="demo-icon" className='feature-icon' />
            </div>
            <div className='feature-text-container'>
              <p className='feature-text'>Isolate work from personal notifications.</p>
            </div>
          </div>
          
        </div>

      </div>
      
      
    </div>
  );
}
