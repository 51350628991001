import { useForm} from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import './signup.css';
import { supabase } from './supabaseClient.js';
import { useState } from "react";

export default function Form() {
  const [isSubmitted,setIsSubmitted] = useState(false);
  const [emailName,setEmailName] = useState("");

  async function sbEnter (data) {
    const {errorSB} = await supabase.from('signups').insert({email_address: data});
    console.log(errorSB);

    if(!errorSB){
      setIsSubmitted(true);
      setEmailName(data);
      console.log(isSubmitted);
    }
  }

  const schema = yup.object().shape({
    email: yup.string().email("Email must be a valid email.").required("Email must be a valid email."),
  });

  const { register, handleSubmit, formState: {errors} } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    console.log(data);
    sbEnter(data.email);
  };

  return (
    <div>
      {!isSubmitted ? (
        <div className="lets-try">
          <p className='dev-warning'>Be the first to hear when we launch.</p>
          <form className="form-wrapper" onSubmit={handleSubmit(onSubmit)}>
            <div className="signup-wrapper">
              <input name="input-text1" autoComplete="on" className="signup-input" type="text" placeholder="Email" {...register("email")} />
              <input name = "input-submit" className="signup-button" type="submit" value="Notify Me"  />
            </div>
            <p className="error-msg">{errors.email?.message}</p>
          </form>
        </div>
        
      ):(
        <div className="after-submit">
          <div>Thanks {emailName}! <br /> Keep an eye out for Notifs Updates.</div>
        </div>
      )}
    </div>
    

    
    
  );
/*
  const [isSubmitted,setIsSubmitted] = useState(false);
  return (
    <TransitionGroup>
      {!isSubmitted ? (
        <CSSTransition key="form" timeout={500} classNames="fade">
          <div className="beggo">
            hello this the start
            <button onClick={()=>{setIsSubmitted(true)}}>press</button>
          </div>
          
        </CSSTransition>
      ) : (
        <CSSTransition key="thankyou" timeout={500} classNames="fade">
          <p className="endo">Thank you for your submission!</p>
        </CSSTransition>
      )}
    </TransitionGroup>
  );*/
}