import './BottomCard.css';


export default function BottomCard() {
  const handleScrollToTop = () => {
    window.scrollTo({top: 0, behavior:'smooth'});
  }
  return (
    <section className="bottom-section">
      <div className="signup-bottom-card">
        <h1 className="bottom-title" >Be the first to hear <br /> when Notifs launches</h1>
        <div className="action-container">
          <button className="bottom-button" onClick={handleScrollToTop}>Sign Up</button>
          <a href="mailto:getnotifs@gmail.com"> 
            <button className="bottom-button">Contact</button>
          </a>
         
        </div>
        
      </div>
    </section>
    
  
  );
}